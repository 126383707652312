/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Header} from "../components/Header.tsx";
import "./styles.scss";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Header), "\n", React.createElement("div", {
    className: "container"
  }, React.createElement(_components.h1, null, "仕事"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "カクヨムビューワー アプリ開発(React Native)"), "\n", React.createElement(_components.li, null, "コミックDAYS Androidアプリ 開発"), "\n", React.createElement(_components.li, null, "コミックDAYS iOSアプリ 開発"), "\n", React.createElement(_components.li, null, "コミックDAYS アプリ用API開発"), "\n", React.createElement(_components.li, null, "コミックDAYS web開発"), "\n", React.createElement(_components.li, null, "コミックガルド+ アプリ用API開発"), "\n", React.createElement(_components.li, null, "webアクション web開発"), "\n", React.createElement(_components.li, null, "技術評論社 WEB+DB Press 「Perl Hackers Hub」記事 共同執筆", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gihyo.jp/dev/serial/01/perl-hackers-hub/007301"
  }, "第73回Perlで作るGraphQL API")), "\n"), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
